import { useQuery } from '@apollo/react-hooks';
import { Divider } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
// import { version } from "mobile-detect";
import EmailIcon from '@material-ui/icons/Email';
import HowToReg from '@material-ui/icons/HowToReg';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import GetApp from '@material-ui/icons/GetApp';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import StyleIcon from '@material-ui/icons/Style';
import numeral from 'numeral';
import React, { useContext } from 'react';
import { Redirect, useLocation, withRouter } from 'react-router-dom';
import {
  AlertDotIcon,
  CouponIcon,
  CustomerIcon,
  GiftBox,
  InboxIcon,
  LogoutIcon,
  OrderIcon,
  PencilIcon,
  ProductIcon,
  RefundIcon,
  SidebarCategoryIcon,
} from '../../../components/AllSvgIcon';
import openNotification, {
  typeNotificaton,
} from '../../../components/openNotification/openNotification';
import { PROJECT } from '../../../constant';
import { AuthContext, Role } from '../../../context/auth';
import { GET_PROFILE } from '../../../graphql/query/user.query';
import {
  CATEGORY,
  CUSTOMERS,
  DASHBOARD,
  GIFTS,
  INTRO_PRODUCT,
  MAKER,
  MAKER_LINK,
  ORDERS,
  POINT,
  POINT_ACTION,
  PRODUCTS,
  SALE,
  SEND_MAIL_SETTING,
  VISITOR_SETTING,
  VR_LINK,
  HANBAITEN_MAIL,
  FLYER,
  TOPICS,
  COUPON,
  TICKETS,
  TICKETSDOWNLOADED,
} from '../../../settings/constants';
import { theme } from '../../../theme';
import { version } from '../../Login/Login';
import {
  ButtonMenu,
  LogoutBtn,
  MenuWrapper,
  NavLink,
  SidebarWrapper,
  Svg,
  Title,
  TitleLayout,
} from './Sidebar.style';

export const sidebarMenus = [
  {
    name: '売上集計',
    path: DASHBOARD,
    exact: true,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <AssignmentIcon fontSize='small' style={{ width: '16px' }} />,
  },
  {
    name: '注文履歴',
    path: ORDERS,
    exact: true,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <OrderIcon />,
  },
  process.env.REACT_APP_PROJECT !== PROJECT.chugoku
    ? {
        name: 'ポイント管理',
        path: POINT,
        exact: true,
        roleAccess: [Role.admin],
        icon: <PencilIcon />,
      }
    : null,

  {
    name: 'ユーザアクティビティ',
    path: POINT_ACTION,
    exact: true,
    roleAccess: [Role.admin],
    icon: <DirectionsRunIcon fontSize='small' style={{ padding: '0' }} />,
  },
  // {
  //   name: "ポイント交換",
  //   path: POINT_EXCHANGE,
  //   exact: true,
  //   roleAccess: [Role.admin],
  //   icon: <GiftBox />,
  // },
  {
    name: 'ユーザ管理',
    path: CUSTOMERS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <CustomerIcon />,
  },
  process.env.REACT_APP_PROJECT === PROJECT.tokyo ||
  process.env.REACT_APP_PROJECT === PROJECT.kyushu ||
  process.env.REACT_APP_PROJECT === PROJECT.hokaido
    ? {
        name: 'メール受信設定',
        path: SEND_MAIL_SETTING,
        exact: true,
        roleAccess: [Role.admin],
        icon: <EmailIcon fontSize='small' style={{ paddingRight: '2px' }} />,
      }
    : null,
  process.env.REACT_APP_PROJECT === PROJECT.tokyo ||
  process.env.REACT_APP_PROJECT === PROJECT.kyushu ||
  process.env.REACT_APP_PROJECT === PROJECT.hokaido
    ? {
        name: '来場者設定',
        path: VISITOR_SETTING,
        exact: true,
        roleAccess: [Role.admin],
        icon: <HowToReg style={{ paddingRight: '5px' }} />,
      }
    : null,
  {
    name: '商品管理',
    path: PRODUCTS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <ProductIcon />,
  },
  process.env.REACT_APP_PROJECT === PROJECT.tokyo ||
  process.env.REACT_APP_PROJECT === PROJECT.kyushu ||
  process.env.REACT_APP_PROJECT === PROJECT.hokaido
    ? {
        name: '景品管理',
        path: GIFTS,
        exact: false,
        roleAccess: [Role.admin],
        icon: <GiftBox />,
      }
    : null,
  // {
  //   name: "価格設定",
  //   path: PRICE_SETTINGS,
  //   exact: false,
  //   roleAccess: [Role.admin, Role.hanbaiten],
  //   icon: <CouponIcon />,
  // },

  {
    name: 'カテゴリー',
    path: CATEGORY,
    exact: false,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <SidebarCategoryIcon />,
  },
  {
    name: 'メーカー',
    path: MAKER,
    exact: false,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <RefundIcon />,
  },
  // process.env.REACT_APP_PROJECT !== PROJECT.tokyo
  //   ?
  // {
  //   name: "メール受信",
  //   path: HANBAITEN_MAIL,
  //   exact: false,
  //   roleAccess: [Role.hanbaiten],
  //   icon: <EmailIcon style={{ paddingRight: "5px" }} />,
  // },
  // : null,
  {
    name: 'VRリンク集',
    path: VR_LINK,
    exact: false,
    roleAccess: [Role.admin],
    icon: <AlertDotIcon />,
  },
  // process.env.REACT_APP_PROJECT !== PROJECT.chugoku
  //   ? {
  //     name: 'ﾒｰｶｰﾘﾝｸ集​',
  //     path: MAKER_LINK,
  //     exact: false,
  //     roleAccess: [Role.admin],
  //     icon: <InboxIcon />,
  //   }
  //   : null,
  // {
  //   name: "キャンペーン",
  //   path: CAMPAIGN,
  //   exact: false,
  //   roleAccess: [Role.admin, Role.hanbaiten],
  //   icon: <PencilIcon />,
  // },
  // {
  //   name: "タグ設定",
  //   path: TAG_SETTINGS,
  //   exact: false,
  //   roleAccess: [Role.admin, Role.hanbaiten],
  //   icon: <DashboardIcon />,
  // },

  process.env.REACT_APP_PROJECT === PROJECT.tokyo ||
  process.env.REACT_APP_PROJECT === PROJECT.kyushu ||
  process.env.REACT_APP_PROJECT === PROJECT.hokaido
    ? {
        name: 'どんどんセール',
        path: SALE,
        exact: false,
        roleAccess: [Role.admin],
        icon: <CouponIcon />,
      }
    : null,
  {
    name: 'ﾋﾞﾃﾞｵﾘﾝｸ集',
    path: INTRO_PRODUCT,
    exact: false,
    roleAccess: [Role.admin],
    icon: <SubscriptionsIcon style={{ paddingRight: '5px' }} />,
  },
  // {
  //   name: 'クーポン',
  //   path: COUPON,
  //   exact: false,
  //   roleAccess: [Role.admin],
  //   icon: <CouponIcon />,
  // },
  // process.env.REACT_APP_PROJECT === PROJECT.tokyo
  //   ? {
  //     name: 'チラシ管理',
  //     path: FLYER,
  //     exact: false,
  //     roleAccess: [Role.admin],
  //     icon: <StyleIcon style={{ paddingRight: "5px" }} />,
  //   }
  //   : null,
  {
    name: 'TOPICS',
    path: TOPICS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <StyleIcon style={{ paddingRight: '5px' }} />,
  },
  {
    name: 'チケット',
    path: TICKETS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <ConfirmationNumber style={{ paddingRight: '5px' }} />,
  },
  {
    name: 'チケット保存管理',
    path: TICKETSDOWNLOADED,
    exact: false,
    roleAccess: [Role.admin],
    icon: <GetApp style={{ paddingRight: '5px' }} />,
  },
];

export default withRouter(function Sidebar({
  refs,
  style,
  onMenuItemClick,
  changeShowSidebar,
}: any) {
  const { signout, role, makeAuthenticated } = useContext(AuthContext);
  // const location = useLocation()
  const { data, loading } = useQuery(GET_PROFILE);

  if (!loading && !data && role === 'hanbaiten') {
    // document.location.reload(true)
    // makeAuthenticated(false)
    signout();
    openNotification(
      typeNotificaton.error,
      `データ取得がエラーされました。\n もう一度ログインしてください。`
    );
    // return <Redirect to='/login' />
  } // when userdata loading error

  return (
    <SidebarWrapper ref={refs} style={style}>
      <MenuWrapper>
        <>
          <TitleLayout>
            <Title>
              管理者画面
              <span
                style={{
                  fontSize: 10,
                  marginTop: 'auto',
                }}
              >
                {version.v}
              </span>
            </Title>
            <ButtonMenu onClick={changeShowSidebar}>
              <ArrowBackIosIcon style={{ fontSize: 20 }} />
            </ButtonMenu>
          </TitleLayout>
        </>
        {!loading && role === 'hanbaiten' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <div style={{ color: theme.colors.primary }}>
              <b>{data?.profile?.username}</b>
            </div>
            <div>
              保有ポイント:
              <span style={{ color: '#c00' }}>
                {numeral(data?.profile?.point).format('0,0.[00]')}
              </span>{' '}
              pts
            </div>
          </div>
        )}
        <Divider />
        {sidebarMenus
          .filter((item) => {
            return item !== null && item.roleAccess.includes(role);
          })
          .map((menu: any, index: number) => (
            <NavLink
              to={menu.path}
              key={index}
              exact={menu.exact}
              activeStyle={{
                color: theme.colors.primary,
                backgroundColor: '#f1f1f1',
              }}
              onClick={onMenuItemClick}
            >
              {menu.icon ? <Svg>{menu.icon}</Svg> : ''}
              {menu.name}
            </NavLink>
          ))}
      </MenuWrapper>
      <LogoutBtn
        onClick={() => {
          signout();
        }}
      >
        <Svg>
          <LogoutIcon />
        </Svg>
        ログアウト
      </LogoutBtn>
    </SidebarWrapper>
  );
});
